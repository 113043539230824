<template>
  <div class="app-container">
    <h2>
      Hey, {{ username }}
    </h2>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'Dashboard',
    data() {
      return {};
    },
    computed: {
      ...mapGetters({
        username: 'account/username',
      }),
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../styles/views/_dashboard.scss';
</style>
